import _map from 'lodash/fp/map'

export type Config = {
  gatewayEndpoint: string
  reCaptchaSiteKey: string
  submissionPathResi: string
  submissionPathSme: string
  submissionPathCricketAustralia: string
  finishedCampaigns: string[]
  temporaryClosedCampaigns: string[]
  salesIdExpiryInMinutes: number
  cannotOperateBefore: string
  cannotOperateAfter: string
  kioskCognito: {
    domain: string
    userPoolId: string
    clientId: string
  }
  qantasRedirectUrl: string
  alinta: {
    apiEndpointUrl: string
    subscriptionKey: string
  }
}

const submissionPathResi = '/invitations/self-signup/resi'
const submissionPathSme = '/invitations/self-signup/sme'
const submissionPathCricketAustralia = '/signup/cricket-australia/applications'

function tryGetWindowConfig(): Config | undefined {
  const settings = (window as any)?.SETTINGS
  const gatewayEndpoint = settings?.gatewayEndpoint
  const reCaptchaSiteKey = settings?.reCaptchaSiteKey
  const finishedCampaigns = _map(
    (str: string) => str.trim().toLowerCase(),
    settings?.finishedCampaigns?.split(',')
  )
  const temporaryClosedCampaigns = _map(
    (str: string) => str.trim().toLowerCase(),
    settings?.temporaryClosedCampaigns?.split(',')
  )
  const cannotOperateBefore = settings?.cannotOperateBefore
  const cannotOperateAfter = settings?.cannotOperateAfter

  if (gatewayEndpoint) {
    return {
      gatewayEndpoint,
      reCaptchaSiteKey,
      submissionPathResi,
      submissionPathSme,
      submissionPathCricketAustralia,
      finishedCampaigns,
      temporaryClosedCampaigns,
      salesIdExpiryInMinutes: Number.parseInt(settings?.salesIdExpiryInMinutes) || 240,
      cannotOperateBefore,
      cannotOperateAfter,
      kioskCognito: settings?.kioskCognito,
      qantasRedirectUrl: settings?.qantasRedirectUrl,
      alinta: settings?.alinta,
    }
  }

  return undefined
}

export function getConfig(): Config {
  const windowConfig = tryGetWindowConfig()
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    return {
      gatewayEndpoint: 'https://localhost:5001',
      reCaptchaSiteKey: windowConfig?.reCaptchaSiteKey || 'MISSING_RECAPTCHA_KEY',
      submissionPathResi,
      submissionPathSme,
      submissionPathCricketAustralia,
      finishedCampaigns: ['icb body corporate'],
      temporaryClosedCampaigns: [],
      salesIdExpiryInMinutes: 999999,
      cannotOperateBefore: '00:01',
      cannotOperateAfter: '23:59',
      kioskCognito: {
        domain: 'affinity-kiosk-local.auth.ap-southeast-2.amazoncognito.com',
        userPoolId: 'ap-southeast-2_k2cyfFcKc',
        clientId: '4hqpai6k9qcqscl518fpftb4jh',
      },
      qantasRedirectUrl: 'https://www.alintaenergy.com.au/',
      alinta: {
        apiEndpointUrl: 'https://alinta-digital-api-train.azure-api.net',
        subscriptionKey: '',
      },
    }
  }

  if (!windowConfig) {
    throw new Error('Missing configuration on window object, app cannot start.')
  }

  return windowConfig
}
